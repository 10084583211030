var GLOBAL = window;

window.IS_MOBILE = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i) != null;
window.IS_DESKTOP = !IS_MOBILE;
window.IS_IOS = (navigator.userAgent.match(/(iPad|iPhone|iPod)/g) ? true : false);
window.IS_IE = navigator.appVersion.indexOf("MSIE") !== -1 || navigator.userAgent.match(/Trident.*rv[ :]*11\./);
window.IS_IE8 = navigator.appVersion.indexOf("MSIE 8") !== -1;
window.IS_TOUCH_DEVICE = 'ontouchstart' in document;
var HTML = document.documentElement;

if (IS_MOBILE) HTML.classList.add("is-mobile");
if (IS_DESKTOP) HTML.classList.add("is-desktop");
if (IS_IOS) HTML.classList.add("is-ios");
if (IS_IE) HTML.classList.add("is-ie");
if (IS_IE8) HTML.classList.add("is-ie8");

if ( typeof window.orientation !== 'undefined' ) {
  GLOBAL.isMobile = true;
  GLOBAL.isDesktop = false;
  $('html').addClass('is-mobile');
} else {
  GLOBAL.isMobile = false;
  GLOBAL.isDesktop = true;
  $('html').addClass('is-desktop');
}

alertify.set({
  buttonReverse: true,
  labels : {
    ok : 'Да',
    cancel : 'Нет'
  }
});

$(function() {
  var $menus = $('.js-menu');

  if (!IS_TOUCH_DEVICE) {
    $menus.on('mouseenter.js-menu', 'li', function() {
      var $this = $(this);

      clearTimeout($this.data('unhoverTimeout'));

      if ($this.hasClass('has-dropdown')) {
        $this.data('hoverTimeout', setTimeout(function() { $this.addClass('is-hovered'); }, 200));
      } else {
        $this.addClass('is-hovered');
      }
    });

    $menus.on('mouseleave.js-menu', 'li', function() {
      var $this = $(this);

      clearTimeout($this.data('hoverTimeout'));

      $this.data('unhoverTimeout', setTimeout(function() { $this.removeClass('is-hovered'); }, 200));
    });
  }

  if (IS_TOUCH_DEVICE) {
    $(document).on('click', function(e) {
      if (!$(e.target).closest('.js-menu').length) {
        $('.js-menu .is-hovered').removeClass('is-hovered');
      }
    });

    $menus.on('click.js-m-menu', 'a', function(e) {
      e.preventDefault();

      var $anchor = $(this);
      var $parent = $anchor.closest('li');
      var hasDropdown = $parent.hasClass('has-dropdown');
      var isHovered = $parent.hasClass('is-hovered');
      var link = $anchor.attr('href');

      $parent.siblings().removeClass('is-hovered');

      if (!hasDropdown && link) {
        window.location.href = $anchor.attr('href');
      } else if (isHovered && link) {
        window.location.href = link;
      } else {
        $parent.toggleClass('is-hovered');
      }
    });
  }
});

(function() {
  // mobile menu

  var openedClass = 'menu-is-opened';

  $(document).on('click', '.js-toggle-mobile-menu', function(e) {
    e.preventDefault();

    $('body').toggleClass(openedClass);

    $('.main-menu .is-hovered').removeClass('is-hovered');

    if ($('body').hasClass(openedClass)) {
      var url = new URL(window.location);
      url.searchParams.set('menu', 'opened');
      window.history.pushState({}, '', url);

      $('body').on('click.close-menu', function(ev) {
        var $target = $(ev.target);

        if ($target.closest('.main-menu').length || $target.hasClass('js-toggle-popup-menu')) {
          return;
        }

        closeMenu(true);
      });
    } else {
      closeMenu(true);
    }
  });

  function closeMenu(back) {
    $('body').removeClass(openedClass).off('click.close-menu');

    var url = new URL(window.location);
    if (url.searchParams.get('menu') && back) {
      window.history.back();
    }
  }

  window.addEventListener('popstate', onPopstate);

  function onPopstate() {
    if ($('body').hasClass(openedClass)) {
      closeMenu(false);
    }
  }

  $(function() {
    var url = new URL(window.location);
    if (url.searchParams.get('menu')) {
      $('.js-toggle-mobile-menu:first').click();
    }
  });
})();

(function() {
  // mobile filters

  var openedClass = 'filter-is-opened';

  $(document).on('click', '.js-toggle-mobile-filters', function(e) {
    e.preventDefault();

    $('body').toggleClass(openedClass);

    if ($('body').hasClass(openedClass)) {
      var url = new URL(window.location);
      url.searchParams.set('filters', 'opened');
      window.history.pushState({}, '', url);
    } else {
      closeFilters(true);
    }
  });

  function closeFilters(back) {
    $('body').removeClass(openedClass).off('click.close-filters');

    var url = new URL(window.location);
    if (url.searchParams.get('filters') && back) {
      window.history.back();
    }
  }

  window.addEventListener('popstate', onPopstate);

  function onPopstate() {
    if ($('body').hasClass(openedClass)) {
      closeFilters(false);
    }
  }

  $(function() {
    var url = new URL(window.location);
    if (url.searchParams.get('filters')) {
      $('.js-toggle-mobile-filters:first').click();
    }
  });
})();

// Custom vh for mobile devices
// ----------------------------
// css example:
// height: calc(var(--vh, 1vh) * 100);

$(window).on('resize initVh', function() {
  var vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', vh + 'px');
}).trigger('initVh');

function initCommonScripts() {
  'use strict';

  var checkValue = function(checkbox) {
    var $checkbox = checkbox.closest('.checkbox');

    if ( checkbox.prop('checked') ) {
      $checkbox.addClass('checked');
    } else {
      $checkbox.removeClass('checked');
    }
  }

  $('label.checkbox :checkbox').each(function() {
    var self = $(this);

    checkValue(self);

    self.on('change.styled-checkbox', function() { checkValue(self); });
  });
}

$(function() {
  initCommonScripts();

  // Вставка стрелки-ссылки на полную новость в последний параграф
  $('.news-announce--more-link').each(function() {
    var self = $(this);
    self.appendTo( self.closest('.news-announce--body').find('p:last') );
  });

  // То же со ссылками Подробнее в деталях туров на витрине
  $('.tour-details--more-link').each(function() {
    var self = $(this);
    self.appendTo( self.closest('.tour-details--description').find('p:last') );
  });

  $('.js-tabs').each(function() {
    $(this).tabs();
  });

  $('.js-product-block').on('click', '.js-tour-details', function() {
    // Показ деталей тура на витрине
    var $tour = $(this).closest('.tour');
    $tour.addClass('active').siblings().removeClass('active');
  }).on('click', '.js-tour-details-close', function() {
    // Закрытие деталей тура на витрине
    var $tour = $(this).closest('.tour');
    $tour.removeClass('active');
  });

  $('body').on('click', '.js-hide-product-details', function() {
    $(this).closest('.product').removeClass('active');
  });

  $('.js-carousel').each(function() {
    var self = $(this),
        prev = self.find('.js-prev'),
        next = self.find('.js-next'),
        size = 3;

    self.find('ul').tosrus({
      infinite: true,
      slides: {
        visible: size,
        slide: size
      },
      buttons: {
        prev: prev,
        next: next
      }
    });

    if ( self.find('li').length <= size ) {
      prev.add(next).hide();
    }
  });

  $('.js-gallery').tosrus({
    wrapper: {
      onClick: 'close'
    },
    keys: {
      close: true
    }
  });

  $('[data-fancybox]').fancybox();

  // OverlayLoader init
  $(document).on('click.overlay', '.js-overlay', function(e) {
    e.preventDefault();
    var $target = $($(this).attr('href'));
    $.overlayLoader(true, { node: $target, onShow: function() {
      $target.find('.form input[type="tel"]').focus();
    }});
  });

  $(document).on('click.overlay', '.js-close-popup', function(e) {
    e.preventDefault();
    var $this = $(this);
    var $popup = $this.closest('.popup').length ? $this.closest('.popup') : $this.closest('[data-popup]');
    $.overlayLoader(false, $popup);
  });

  $('input[type="tel"]').each(function() {
    $(this).mask('+7 (999) 999-99-99', { placeholder: '_' });
  });

  $(document.body)
    .on('yiiListViewUpdated', function(event, id, data) {
      initCommonScripts();
      var $catalog = $('.js-product-block');
      var scrollSpeed = Math.abs( $(window).scrollTop() ) * 0.3;
      if ( $catalog.length ) {
        $(window).scrollTo( $catalog, {
          duration: scrollSpeed
        });
      }
    }).on('overlayLoaderShow', function(e, $node) {
      $node.find('.autofocus-inp').focus();
    });
});
